import { Route,Routes } from "react-router-dom";
import Header from "../Header";
import SuccessScreen from "../../Pages/successScreen";
import LandingPage from "../../Pages/LandingPage";

const App = () => {
  return (
    <>
    <Header/>
    <Routes path="/">
      <Route exact path="/"element={<LandingPage/>} ></Route>
      <Route  path="/success" element={<SuccessScreen/>}></Route>
    </Routes>
    </>
  );
};

export default App;
