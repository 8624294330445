import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Heading from "@tds/core-heading";
import Text from "@tds/core-text";
import Box from "@tds/core-box";

import Container from "../../components/Container";
import { scrollTop } from "../../utils";

const SuccessScreen = () => {
  const [t,i18n] = useTranslation();
  console.log("Active language:", i18n.language);

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <Container>
      <Box between={3} inset={3}>
        <Heading level="h2">{t("successPageTitle")}</Heading>
        <Text>{t("successPageMessage")} </Text>
      </Box>
    </Container>
  );
};

export default SuccessScreen;
