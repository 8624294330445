import React from "react";
import "./style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { addDays } from "date-fns";
import { Field } from "formik";
import Input from "@tds/core-input";
import Checkbox from "@tds/core-checkbox";
import Box from "@tds/core-box";
import Radio from "@tds/core-radio";
import Notification from "@tds/core-notification";
import Text from "@tds/core-text";
import InputMask from "react-input-mask";
import Select from "@tds/core-select";
import FormCol from "./FormCol";
import TextArea from "@tds/core-text-area";
import { Tooltip } from "react-tippy";
import 'react-tippy/dist/tippy.css'

import AddressField from "./AddressField";
const CustomField = ({
  name,
  label,
  type,
  touched,
  errors,
  isSameAs,
  value,
  tooltip,
  hint,
  onChange,
  minLength,
  maxLength,
  onChange: onFieldChange,
  options,
  placeholder,
  disabled,
  removeLocalStorageOnChange = false,
  setAddress,
  values,
  setValues,
  isToltip,
  noteText
}) => {

  return (
    <FormCol>
      <Field name={name}>
        {({ field: { name: fieldName, onBlur, onChange, value } }) => {
          return (
            <>
              {(type === "checkbox" && (
                <Checkbox
                  label={label || ""}
                  type={type}
                  error={touched[name] && errors[name]}
                  feedback={touched[name] && errors[name] && "error"}
                  value={value || ""}
                  checked={value}
                  name={fieldName}
                  hint={hint || ""}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e);
                    onFieldChange(e);
                  }}
                />
              )) ||
                (type === "radio" && (
                  <Box tag="fieldset" between={2}>
                    <>
                      <legend>
                        <Text bold size="medium">
                          {label || ""}
                          {isToltip && (
                            <button
                              type="button"
                              style={{
                                background: "none",
                                color: "inherit",
                                border: "none",
                                padding: "0",
                                font: "inherit",
                                cursor: "pointer",
                                outline: "inherit",
                                marginLeft: "10px",
                              }}
                            >
                              <Tooltip
                                // options
                                title={noteText}
                                position="top"
                                trigger="click"
                                theme="light"
                                size="regular"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "black",
                                    borderRadius: "50%",
                                    width: "20px",
                                    height: "20px",
                                    color: "#fff",
                                    fontSize: "18px",
                                    textAlign: "center",
                                  }}
                                >
                                  ?
                                </div>
                              </Tooltip>
                            </button>
                          )}
                        </Text>
                      </legend>
                      {options &&
                        options.map((option) => (
                          <Radio
                            key={option}
                            label={option}
                            error={touched[name] && errors[name]}
                            feedback={touched[name] && errors[name] && "error"}
                            name={name}
                            value={option}
                            checked={value === option}
                            onChange={onChange}
                            tooltip={tooltip}
                          />
                        ))}
                    </>
                  </Box>
                )) ||
                (type === "mask" && (
                  <InputMask
                    label={label || ""}
                    name={fieldName}
                    mask="(999) 999-9999"
                    value={value || ""}
                    onBlur={onBlur}
                    hint={hint}
                    onChange={(e) => {
                      onChange(e);
                      onFieldChange(e);
                    }}
                  >
                    {({
                      value: maskValue,
                      onChange: onMaskChange,
                      name: maskName,
                    }) => (
                      <Input
                        name={maskName}
                        value={maskValue}
                        onChange={onMaskChange}
                        hint={hint || ""}
                        error={touched[name] && errors[name]}
                        feedback={touched[name] && errors[name] && "error"}
                        type="text"
                        label={label || ""}
                        placeholder={placeholder || ""}
                        tooltip={tooltip}
                      />
                    )}
                  </InputMask>
                )) ||
                (type === "select" && (
                  <Select
                    label={label || ""}
                    error={touched[name] && errors[name]}
                    feedback={touched[name] && errors[name] && "error"}
                    name={fieldName}
                    onBlur={onBlur}
                    hint={hint || ""}
                    onChange={(e) => {
                      onChange(e);
                      onFieldChange(e);
                    }}
                    tooltip={tooltip}
                    value={value || ""}
                    options={options}
                    placeholder={placeholder || ""}
                  />
                )) ||
                (type === "appointmentDateSelect" && (
                  <Box tag="fieldset" between={2}>
                    <legend>
                      <Text bold size="medium">
                        {label}
                      </Text>
                      <Notification copy="en">
                        <Text small>Note: </Text>By selecting flexible date, the
                        next available date during your business hours will be
                        selected for you.
                      </Notification>
                    </legend>
                    <Select
                      // label={label || ""}
                      error={touched[name] && errors[name]}
                      feedback={touched[name] && errors[name] && "error"}
                      name={fieldName}
                      onBlur={onBlur}
                      hint={hint || ""}
                      onChange={(e) => {
                        onChange(e);
                        onFieldChange(e);
                      }}
                      tooltip={tooltip}
                      value={value || ""}
                      options={options}
                      placeholder={placeholder || ""}
                    />
                  </Box>
                )) ||
                (type === "Address" && (
                  <AddressField
                    name={name}
                    label={label}
                    type={type}
                    touched={touched}
                    errors={errors}
                    isSameAs={isSameAs}
                    value={value}
                    tooltip={tooltip}
                    hint={hint}
                    setValues={setValues}
                    minLength={minLength}
                    maxLength={maxLength}
                    options={options}
                    placeholder={placeholder}
                    disabled={disabled}
                    removeLocalStorageOnChange={removeLocalStorageOnChange}
                    setAddress={setAddress}
                    values={values}
                  />
                )) ||
                (type === "textNote" && (
                  <Box tag="fieldset" between={2}>
                    <legend>
                      <Text bold size="medium">
                        {label || ""}
                      </Text>
                    </legend>
                    <TextArea
                      label={""}
                      error={touched[name] && errors[name]}
                      feedback={touched[name] && errors[name] && "error"}
                      name={fieldName}
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(e);
                        onFieldChange(e);
                      }}
                      tooltip={tooltip}
                      value={value || ""}
                      options={options}
                      placeholder={placeholder || ""}
                    />
                  </Box>
                )) ||
                (type === "datePicker" && (
                  <Box tag="fieldset" between={2}>
                    <legend>
                      <Text bold size="medium">
                        {label}
                      </Text>
                    </legend>
                    <div className="myContainer">
                      <DatePicker
                        name={fieldName}
                        label={label}
                        error={touched[name] && errors[name]}
                        feedback={touched[name] && errors[name] && "error"}
                        className="myDatePicker"
                        selected={value}
                        onChange={(date) => {
                          onFieldChange(date);
                        }}
                        minDate={addDays(new Date(), 7)}
                        value={value}
                        dateFormat="dd/MM/yyyy"
                        showDisabledMonthNavigation
                        placeholderText={placeholder || ""}
                      />
                    </div>
                  </Box>
                )) || (
                  <>
                    <Input
                      label={label || ""}
                      type={type}
                      error={touched[name] && errors[name]}
                      feedback={touched[name] && errors[name] && "error"}
                      name={fieldName}
                      onBlur={onBlur}
                      hint={hint || ""}
                      placeholder={placeholder || ""}
                      onChange={(e) => {
                        onChange(e);
                        onFieldChange(e);
                      }}
                      onWheel={(e) => e.target.blur()}
                      minLength={minLength}
                      maxLength={maxLength}
                      disabled={disabled}
                      tooltip={tooltip}
                      value={value || ""}
                      options={options}
                    />
                  </>
                )}
            </>
          );
        }}
      </Field>
    </FormCol>
  );
};

CustomField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  tooltip: PropTypes.object,
  onChange: PropTypes.func,
  minLength: PropTypes.string,
  min: PropTypes.func,
  maxLength: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any),
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  isSameAs: PropTypes.bool,
  hint: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  setValues: PropTypes.func.isRequired,
};

CustomField.defaultProps = {
  type: "text",
  tooltip: undefined,
  onChange: () => {},
  options: [],
};

export default CustomField;
