import * as Yup from "yup";

const phoneRegExp = /^(\d)(?!\1+$)\d{9}$/;

export const fwaSchema = (t) => {
	return Yup.object().shape({
		isSmartHubReceived: Yup.string().required(t(`Field Required`)),
		isChecklistCompleted: Yup.string().required(t(`Field Required`)),
		businessName: Yup.string().required(t(`Business Name Required`)),
		isAnotherAddress: Yup.string().required(t(`Field Required`)),
		isServiceAddress: Yup.string().required(t("Service Address Required")),
		latitudes: Yup.string().when("isServiceAddress", {
			is: (value) => value === "Latitude/Longitude",
			then: (schema) => schema.required(() => t("Latitude Required"))
		}),
		longitude: Yup.string().when("isServiceAddress", {
			is: (value) => value === "Latitude/Longitude",
			then: (schema) => schema.required(() => t("Longitude Required"))
		}),
		streetNumber: Yup.string().when("isServiceAddress", {
			is: (value) => value === "Registered Address",
			then: (schema) => schema.required(() => t("Street Number Required"))
		}),
		streetName: Yup.string().when("isServiceAddress", {
			is: (value) => value === "Registered Address",
			then: (schema) => schema.required(() => t("Street Name Required"))
		}),
		// unitNumber: Yup.string().when("isServiceAddress", {
		// 	is: (value) => value === "Registered Address",
		// 	then: (schema) => schema.required(() => t("Unit Number Required"))
		// }),
		city: Yup.string().when("isServiceAddress", {
			is: (value) => value === "Registered Address",
			then: (schema) => schema.required(() => t("City Required"))
		}),
		province: Yup.string().when("isServiceAddress", {
			is: (value) => value === "Registered Address",
			then: (schema) => schema.required(t("Province Required"))
			.test(
				"is-within-province",
				"Address can only be selected from Alberta or British Colombia",
				function (value) {
					if (!value) return false;
					return ["alberta", "british columbia"].includes(value.toLowerCase());
				}
			),
		}),
		postalCode: Yup.string().when("isServiceAddress", {
			is: (value) => value === "Registered Address",
			then: (schema) => schema.required(() => t("Postal Code Required"))
		}),
		email: Yup.string().email(t("Email Invalid")).required(t("Email Required")),
		accountNumber: Yup.string()
			.required("Account Number Required")
			.min(8, "Account Number must be 8 digits")
			.max(8, "Account Number can't be more than 8 digits"),
		subscriberNumber: Yup.string()
			.required(t("Subscriber Number Required"))
			.min(14, "Subscriber Number must be 10 digits")
			.max(14, "Subscriber Number no more than 10 digits")
			.test("is-subscriber-number", t("Must be 10 digits"), (value) => {
				const number = value && value.replace(/[-() _]/g, "");
				return phoneRegExp.test(number);
			}),
		additionalInfo: Yup.string()
	});
};
