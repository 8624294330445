import React, { useState } from "react";
import "./style.css";
import Heading from "@tds/core-heading";
import Box from "@tds/core-box";
import PropTypes from "prop-types";
import Button from "@tds/core-button";
import FlexGrid from "@tds/core-flex-grid";
import { useTranslation } from "react-i18next";
import Text from "@tds/core-text";
import { fwaSchema } from "../schema";
import FormCol from "../../components/Form/FormCol";
import Notification from "@tds/core-notification";
import Field from "../../components/Form/Field";
import HairlineDivider from "@tds/core-hairline-divider";
import axios from "axios";
import Container from "../../components/Container";
import { Formik, Form } from "formik";
import config from "../../config";
import Spinner from "@tds/core-spinner";
import { useNavigate } from "react-router-dom";
import Tooltip from "@tds/core-tooltip";

const LandingPage = () => {
  const [showFields, setShowFields] = useState();
  const [showRadioFields, setShowRadioFields] = useState();
  const [showSubmit, setShowSubmit] = useState();
  const [showAddress, setShowAddress] = useState();
  const [showNote, setShowNote] = useState();
  const [showNote1, setShowNote1] = useState();
  const [showNote2, setShowNote2] = useState();
  const [showNote3, setShowNote3] = useState();
  const [t] = useTranslation();
  const { API_BASE } = config;
  const [isLoading, setIsLoading] = useState(false);
  const formikKeys = {
    isSmartHubReceived: "isSmartHubReceived",
    isChecklistCompleted: "isChecklistCompleted",
    isServiceAddress: "isServiceAddress",
    isSmartPhoneInternet: "isSmartPhoneInternet",
    isPhysicalTask: "isPhysicalTask",
    isToolEquipments: "isToolEquipments",
    iscomfortableSpending: "iscomfortableSpending",
    isAnotherAddress: "isAnotherAddress",
  };
  let navigate = useNavigate();
  const initialValues = {
    isSmartHubReceived: "",
    isChecklistCompleted: "",
    isSmartPhoneInternet: "",
    isPhysicalTask: "",
    isToolEquipments: "",
    iscomfortableSpending: "",
    isAnotherAddress: "",
    isServiceAddress: "",
    businessName: "",
    streetNumber: "",
    streetName: "",
    unitNumber: "",
    province: "",
    postalCode: "",
    latitudes: "",
    longitude: "",
    email: "",
    city: "",
    accountNumber: "",
    subscriberNumber: "",
  };

  const getBusinessAddress = (values) =>
    `${values.streetNumber} ${values.streetName} ${
      values.unitNumber && `${values.unitNumber} `
    }${values.city}, ${values.province} ${values.postalCode}`;

  const onFormSubmitted = (values) => {
    setIsLoading(true);
    const payload = {
      isSmartHubReceived: values.isSmartHubReceived,
      isChecklistCompleted: values.isChecklistCompleted,
      businessName: values.businessName,
      isServiceAddress: values.isServiceAddress,
      latitudes: values.latitudes ? values.latitudes : " ",
      longitude: values.longitude ? values.longitude : " ",
      businessAddress:
        values.isServiceAddress === "Registered Address"
          ? getBusinessAddress(values)
          : " ",
      email: values.email,
      accountNumber: values.accountNumber,
      subscriberNumber: values.subscriberNumber,
      isSmartPhoneInternet:
        values.isChecklistCompleted === "No"
          ? values.isSmartPhoneInternet
          : " ",
      isPhysicalTask:
        values.isChecklistCompleted === "No" ? values.isPhysicalTask : " ",
      isToolEquipments:
        values.isChecklistCompleted === "No" ? values.isToolEquipments : " ",
      iscomfortableSpending:
        values.isChecklistCompleted === "No"
          ? values.iscomfortableSpending
          : " ",
      isAnotherAddress: values.isAnotherAddress,
    };

    axios
      .post(`${API_BASE}install-diy-request/create`, payload)
      .then(({ fwaData }) => {
        setIsLoading(false);
        if (fwaData.success === 1) {
          navigate(`/success`);
        }
      })
      .catch(() => {
        setIsLoading(false);
        navigate(`/success`);
      });
  };

  const handleChange = (event, values, setValues) => {
    const {
      target: { value, name },
    } = event;

    if (name === formikKeys.isSmartHubReceived && value === t("No")) {
      setShowSubmit(false);
    }
    if (name === formikKeys.isSmartHubReceived && value === t("Yes")) {
      setShowSubmit(true);
    }
    if (name === formikKeys.isChecklistCompleted && value === t("No")) {
      setShowFields(false);
      setShowRadioFields(true);
    }
    if (
      name === formikKeys.isSmartPhoneInternet &&
      value === t("No") &&
      values.isChecklistCompleted === t("No")
    ) {
      setShowNote1(false);
      setShowNote(true);
      setShowFields(false);
    }
    if (
      name === formikKeys.isSmartPhoneInternet &&
      value === t("Yes") &&
      values.isChecklistCompleted === t("No")
    ) {
      setShowNote1(true);
      setShowNote(false);
    }
    if (
      name === formikKeys.isPhysicalTask &&
      value === t("No") &&
      values.isSmartHubReceived === t("Yes")
    ) {
      setShowNote2(false);
      setShowNote(true);
      setShowFields(false);
    }
    if (
      name === formikKeys.isPhysicalTask &&
      value === t("Yes") &&
      values.isChecklistCompleted === t("No") &&
      values.isSmartPhoneInternet === t("Yes")
    ) {
      setShowNote2(true);
      setShowNote(false);
    }

    if (
      name === formikKeys.isToolEquipments &&
      value === t("Yes") &&
      values.isChecklistCompleted === t("No")
    ) {
      setShowNote3(true);
      setShowNote(false);
    }
    if (
      name === formikKeys.isToolEquipments &&
      value === t("No") &&
      values.isChecklistCompleted === t("No")
    ) {
      setShowNote3(false);
      setShowNote(true);
      setShowFields(false);
    }

    if (
      name === formikKeys.iscomfortableSpending &&
      value === t("No") &&
      values.isChecklistCompleted === t("No")
    ) {
      setShowFields(false);
      setShowNote(true);
    }
    if (
      name === formikKeys.iscomfortableSpending &&
      value === t("Yes") &&
      values.isChecklistCompleted === t("No")
    ) {
      setShowFields(true);
      setShowNote(false);
    }
    if (
      name === formikKeys.isServiceAddress &&
      value === t("Registered Address")
    ) {
      setShowAddress(true);
    }

    if (
      name === formikKeys.isServiceAddress &&
      value === t("Latitude/Longitude")
    ) {
      setShowAddress(false);
    }
    if (
      name === formikKeys.isChecklistCompleted &&
      value === t("Yes") &&
      values.isSmartHubReceived === t("Yes")
    ) {
      setShowFields(true);
      setShowRadioFields(false);
    }

    if (
      name === formikKeys.isSmartHubReceived &&
      value === t("Yes") &&
      values.isChecklistCompleted === t("Yes")
    ) {
      setShowFields(true);
      setShowRadioFields(false);
    }
  };

  return (
    <Container>
      <Spinner fullScreen label={t("SUBMITTING_INFO")} spinning={isLoading} />
      <Box between={3} inset={3} padding={100}>
        <Heading level="h2">{t("LANDING_PAGE_TITLE")}</Heading>
        <Formik
          validationSchema={fwaSchema(t)}
          onSubmit={onFormSubmitted}
          enableReinitialize
          initialValues={initialValues}
        >
          {({ handleSubmit, errors, touched, values, setValues }) => (
            <Form
              onSubmit={handleSubmit}
              onChange={(e) => handleChange(e, values, setValues)}
            >
              <Box between={2} inset={3}>
                <Field
                  name="isSmartHubReceived"
                  type="radio"
                  label={t("isSmartHubReceived")}
                  values={values}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      isSmartHubReceived: e.target.value,
                    })
                  }
                  options={[
                    t("isSmartHubReceived_Options.optionOne"),
                    t("isSmartHubReceived_Options.optionTwo"),
                  ]}
                  isToltip={true}
                  noteText="Hint: TELUS will share a satellite image of the location where you should install your Smart Hub ODU."
                  touched={touched}
                  errors={errors}
                />
                {showSubmit && (
                  <Box>
                    <Field
                      name="isChecklistCompleted"
                      type="radio"
                      label={t("isChecklistCompleted")}
                      options={[
                        t("isChecklistCompleted_Options.optionOne"),
                        t("isChecklistCompleted_Options.optionTwo"),
                      ]}
                      values={values}
                      touched={touched}
                      errors={errors}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          isChecklistCompleted: e.target.value,
                        })
                      }
                    />
                    {showRadioFields && (
                      <box>
                        <Field
                          name="isSmartPhoneInternet"
                          type="radio"
                          label={t("isSmartPhoneInternet")}
                          options={[
                            t("isSmartPhoneInternet_Options.optionOne"),
                            t("isSmartPhoneInternet_Options.optionTwo"),
                          ]}
                          values={values}
                          touched={touched}
                          errors={errors}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              isSmartPhoneInternet: e.target.value,
                            })
                          }
                        />
                        {showNote1 && (
                          <Field
                            name="isPhysicalTask"
                            type="radio"
                            label={t("isPhysicalTask")}
                            options={[
                              t("isPhysicalTask_Options.optionOne"),
                              t("isPhysicalTask_Options.optionTwo"),
                            ]}
                            values={values}
                            touched={touched}
                            errors={errors}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                isPhysicalTask: e.target.value,
                              })
                            }
                          />
                        )}

                        {showNote1 && showNote2 && (
                          <Field
                            name="isToolEquipments"
                            type="radio"
                            label={t("isToolEquipments")}
                            options={[
                              t("isToolEquipments_Options.optionOne"),
                              t("isToolEquipments_Options.optionTwo"),
                            ]}
                            values={values}
                            touched={touched}
                            errors={errors}
                            noteText="Hint: You may also need: duct tape, stapler, measuring tape, mark pen, stud finder, pair of scissors, exacto knife. Recommended safety equipment includes safety glasses, safety helmet, safety gloves, anti-slip work boots."
                            isToltip={true}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                isToolEquipments: e.target.value,
                              })
                            }
                          />
                        )}

                        {showNote1 && showNote2 && showNote3 && (
                          <Field
                            name="iscomfortableSpending"
                            type="radio"
                            label={t("iscomfortableSpending")}
                            options={[
                              t("iscomfortableSpending_Options.optionOne"),
                              t("iscomfortableSpending_Options.optionTwo"),
                            ]}
                            values={values}
                            touched={touched}
                            errors={errors}
                            noteText="Hint: The installation may take up to 3.5 hours if drilling is required."
                            isToltip={true}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                iscomfortableSpending: e.target.value,
                              })
                            }
                          />
                        )}
                      </box>
                    )}
                    {(showFields ||
                      (values.iscomfortableSpending === "Yes" &&
                        showNote1 &&
                        showNote2 &&
                        showNote3)) && (
                      <Box>
                        <Field
                          name="businessName"
                          label={t("BUSINESS_NAME")}
                          touched={touched}
                          errors={errors}
                          placeholder="Please Enter Business Name"
                          onChange={(e) =>
                            setValues({
                              ...values,
                              businessName: e.target.value,
                            })
                          }
                        />

                        <Field
                          name="isServiceAddress"
                          type="radio"
                          label={t("isServiceAddress")}
                          values={values}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              isServiceAddress: e.target.value,
                            })
                          }
                          options={[
                            t("isServiceAddress_Options.optionOne"),
                            t("isServiceAddress_Options.optionTwo"),
                          ]}
                          touched={touched}
                          errors={errors}
                        />
                        {showAddress && (
                          <Field
                            type="Address"
                            touched={touched}
                            errors={errors}
                            values={values}
                            setValues={setValues}
                            placeholder="Please Enter Business Address"
                            readonly={false}
                            disabled={false}
                            isSameAs={false}
                          />
                        )}
                        {showAddress === false && (
                          <>
                            <Field
                              name="latitudes"
                              type="number"
                              label={t("COORDINATES")}
                              touched={touched}
                              errors={errors}
                              placeholder="Latitude"
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  latitudes: e.target.value,
                                })
                              }
                              tooltip={
                                <Tooltip className="tooltipbar" copy="en">
                                  Hint: Select your location in Google Maps and
                                  copy the coordinates it provides here.
                                </Tooltip>
                              }
                            />
                            <Field
                              name="longitude"
                              type="number"
                              touched={touched}
                              errors={errors}
                              placeholder="Longitude"
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  longitude: e.target.value,
                                })
                              }
                            />
                          </>
                        )}
                        <Field
                          name="email"
                          type="email"
                          label={t("EMAIL")}
                          touched={touched}
                          errors={errors}
                          placeholder="Please Enter Email Address"
                          onChange={(e) =>
                            setValues({
                              ...values,
                              email: e.target.value,
                            })
                          }
                        />
                        <Field
                          name="accountNumber"
                          type="number"
                          label={t("TELUS_ACCOUNT_NUMBER")}
                          touched={touched}
                          errors={errors}
                          placeholder="Please Enter Account Number"
                          onChange={(e) =>
                            setValues({
                              ...values,
                              accountNumber: e.target.value,
                            })
                          }
                          tooltip={
                            <Tooltip className="tooltipbar" copy="en">
                              Hint: TELUS account number can be found in your
                              contract
                            </Tooltip>
                          }
                        />
                        <Field
                          name="subscriberNumber"
                          type="mask"
                          CustomField={"hello"}
                          label={t("TELUS_SUBSCRIBER_NUMBER")}
                          touched={touched}
                          errors={errors}
                          placeholder="Please Enter Subscriber Number"
                          tooltip={
                            <Tooltip className="tooltipbar" copy="en">
                              Hint: Phone number associated to ODU device
                            </Tooltip>
                          }
                          onChange={(e) =>
                            setValues({
                              ...values,
                              subscriberNumber: e.target.value,
                            })
                          }
                        />
                        <Field
                          name="isAnotherAddress"
                          type="radio"
                          label={t("isAnotherAddress")}
                          options={[
                            t("isAnotherAddress_Options.optionOne"),
                            t("isAnotherAddress_Options.optionTwo"),
                          ]}
                          values={values}
                          touched={touched}
                          errors={errors}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              isAnotherAddress: e.target.value,
                            })
                          }
                        />
                        <FlexGrid.Row>
                          {values.isAnotherAddress === "Yes" && (
                            <>
                              <Box between={4}>
                                <HairlineDivider gradient />
                                <Notification copy="en">
                                  <Text bold>{t("NOTE ")}: </Text>{" "}
                                  {t("isAnotherAddressMessage")}
                                </Notification>
                              </Box>
                            </>
                          )}
                        </FlexGrid.Row>
                        <FlexGrid.Row horizontalAlign="center">
                          <FormCol md={6} xs={12}>
                            <Button
                              type="submit"
                              variant="standard"
                              rank="main"
                            >
                              {t(`submitButtonLabel`)}
                            </Button>
                          </FormCol>
                        </FlexGrid.Row>
                      </Box>
                    )}
                    <FlexGrid.Row>
                      {showRadioFields &&
                        (showNote ||
                          values.isSmartPhoneInternet === "No" ||
                          values.isPhysicalTask === "No" ||
                          values.isToolEquipments === "No" ||
                          values.iscomfortableSpending === "No") && (
                          <>
                            <Box between={4}>
                              <HairlineDivider gradient />
                              <Notification copy="en">
                                <Text bold>{t("NOTE ")}: </Text>{" "}
                                {t("isSmartPhoneInternetMessage")}
                                <a
                                  href={t("isSmartPhoneInternetMessageLink")}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {t("isSmartPhoneInternetMessageLink")}
                                </a>
                              </Notification>
                            </Box>
                          </>
                        )}
                    </FlexGrid.Row>
                  </Box>
                )}
                <FlexGrid.Row>
                  {showSubmit === false && (
                    <>
                      <Box between={4}>
                        <HairlineDivider gradient />
                        <Notification copy="en">
                          <Text bold>{t("NOTE ")}: </Text>{" "}
                          {t("isSmartHubMessage")}
                        </Notification>
                      </Box>
                    </>
                  )}
                </FlexGrid.Row>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

LandingPage.propTypes = {
  onSubmit: PropTypes.func,
};

export default LandingPage;
